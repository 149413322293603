import React, { useEffect, useState, useCallback } from 'react';
import styles from './index.module.less';
import CloseNotiIcon from '@/assets/svg/close_noti_icon.svg';
import cn from 'classnames';

const TopNotification = ({
  title,
  content,
  keyword,
}: PageTmpl & { content?: string }) => {
  const [topNotiVisible, setTopNotiVisible] = useState(false);

  // 关闭提示，用户关闭过一次后就不再展示
  const onClose = () => {
    window.localStorage.setItem('bkeWebsiteNotiStatus', 'false');
    window.localStorage.setItem('bkeWebsiteNotiContent', content); // 记录下内容用作下次比对用
    setTopNotiVisible(false);
  };

  // 判断当前用户是否已经关闭过该提示
  const checkNotiStatus = useCallback(() => {
    const notiStatus = window.localStorage.getItem('bkeWebsiteNotiStatus');
    if (notiStatus === null) {
      // 初始化
      window.localStorage.setItem('bkeWebsiteNotiStatus', 'true');
      setTopNotiVisible(true);
    } else if (notiStatus === 'true') {
      setTopNotiVisible(true);
    } else {
      const notiContent = window.localStorage.getItem('bkeWebsiteNotiContent');
      if (notiContent !== content) {
        setTopNotiVisible(true);
      } else {
        setTopNotiVisible(false);
      }
    }
  }, [content]);

  useEffect(() => {
    // 只有 homepage 需要展示 top notification
    // 之前title属性传了页面的标题，如果标题改了此处就没办法正确检验主页，此处添加keyword检验
    if (title === 'Home' || keyword === 'Home') {
      checkNotiStatus();
    } else {
      setTopNotiVisible(false);
    }
  }, [title, checkNotiStatus, keyword]);

  if (!topNotiVisible || !content) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <div className={cn('content', styles.box)}>
        <p className="col-md-9 font-lg">{content}</p>
        <div className={styles.closeBtn} onClick={onClose}>
          <CloseNotiIcon />
        </div>
      </div>
    </div>
  );
};

export default TopNotification;
